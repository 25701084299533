const menuToggle = document.querySelector<HTMLButtonElement>(".menu-toggle");

menuToggle?.addEventListener("click", () => {
    const primaryMenu =
        document.querySelector<HTMLElement>(".mobile-navigation");

    if (!primaryMenu) {
        return;
    }

    if (primaryMenu.classList.contains("toggled")) {
        primaryMenu.classList.remove("toggled");
        menuToggle.ariaExpanded = "false";
    } else {
        primaryMenu.classList.add("toggled");
        menuToggle.ariaExpanded = "true";
    }
});
